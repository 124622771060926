<template>
  <div class="row">
    <div class="col-lg-12" v-for="(item, index) in headerTitle" :key="index">
      <div class="iq-accordion career-style faq-style">
        <div class="card iq-accordion-block accordion-active p-0">
          <div
            class="active-faq clearfix card-header d-flex justify-content-between"
          >
            <div class="header-title">
              <h4 class="card-title">{{ item.name }}</h4>
            </div>
          </div>
          <div class="accordion-details card-body">
            <p class="mb-0 p-0">{{ item.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TermsOfService',
  data() {
    return {
      headerTitle: [
        {
          name: '所有用戶的使用條款',
          text: '資料準備中.....'
        },
        {
          name: '粉絲使用條款',
          text: '資料準備中.....'
        },
        {
          name: '創作者使用條款',
          text: '資料準備中.....'
        },
        {
          name: '可接受的使用政策',
          text: '資料準備中.....'
        },
        {
          name: '平台到商業監管條款',
          text: '資料準備中.....'
        }
      ]
    }
  }
}
</script>
